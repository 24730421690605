<template>

     <div  v-if="psummary !='e'" id="nextBtn" class="navBtn" v-on:click="moveDirection('next')">
      <i class="fal fa-chevron-right"></i>
     </div>
     <div  v-if="psummary !='s'" id="prevBtn" class="navBtn" v-on:click="moveDirection('prev')">
       <i class="fal fa-chevron-left"></i>
     </div>
      <div
       v-if="itemData.programItem"
        id="previewContainer"
       :class="' content-preview '"
        :style="`
          background-color: ${itemData.programItem.backgroundColor};
          background-image: url(${itemData.programItem.graphic});
          background-size: cover;`"
      > 
        <div
          id="logoContainer"
          :class="'overlayContainer ' + logoPositionClass"
        >
          <img
           class="logo-preview"
            id="coachLogo"
            v-if="itemData.programItem.showLogo"
            :src="currentCoach.coach.coachLogo || ''"
           
          />
        </div>
        <div
          id="itemTextContainer"
          :class="'overlayContainer ' + fadeDirectionClass + ' ' + textPositionClass"
        >
          <div
            id="itemText"
            v-html="preserveHTML"
            :style="getTextPreviewStyle()"
            class="text-preview"
          ></div>
        </div>
      </div>

</template>
<script>

export default {
  props: ["itemData", "currentCoach", "psummary", "fadeDirectionClass"],
  emits: ["next", "prev"],

  methods: {
    getTextPreviewStyle() {
      return `font-family: '${this.itemData.programItem.fontFamily}' !important; font-size:${this.itemData.programItem.fontSize}`;
    },

    getLogoPreviewStyle() {
      return `background-image: url(${this.currentCoach.coachLogo}); grid-area: ${this.itemData.programItem.logoPositionY}${this.itemData.programItem.logoPositionX};`;
    },
    moveDirection(dir){
      console.log(dir, "<-- dir")
       
      this.$emit(dir)
    },
  
  },
  updated:()=>{
    //console.log("BEFORE MOUNT")
    
  },
  computed: {
    preserveHTML() {
      if (!this.itemData.programItem || !this.itemData.programItem.text) return "";
      return (
        this.itemData.programItem.text
          .replaceAll("  ", "&nbsp;&nbsp;")
          .replaceAll("<p><br></p>", "<hr>") || ""
      );
    },
    logoPositionClass() {
      return `${this.itemData.programItem.logoPositionX} ${this.itemData.programItem.logoPositionY}`;
    },
    textPositionClass() {
      return `${this.itemData.programItem.textPositionY}`;
    },
    
  },
};
</script>
<style scoped>


/* .overlayContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  top: 0;
  left: 0;
} */



.navBtn{
  position:absolute;
  top: 45vh;
  width: 21px;
  background-color: rgba(33,33,33,.4);
  padding: 5px;
  font-size: .8rem;
  z-index: 1000;
 
}
#nextBtn{
  right: 0px;
}






.flex {
  display: flex;
  width: 100%;
  height: 100%;
}
#contentBody {
  text-align: center;
  padding: 40px;
  font-size: 64px;
  line-height: 54px;
  color: darkgreen;
}

.bg-preview {
  width: 100%;
  height: calc(100vh - 56px);
  background-size: cover;
  z-index: 10;
}
.text-preview {
  z-index: 500;
 
}

.content-preview {
  position: flex;
  width: 100vw;
  height: calc(100vh - 50px);
  padding: 0px;
  margin: auto;
}
.bg-preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 10;
}
.overlayContainer {
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 7px;
  display: flex;
}
.overlayContainer > .text-preview {
  z-index: 50;
  width: 100%;
  
}
 p {
  margin-top: 0px !important;
}
.xLeft {
  justify-content: left;
}
.xCenter {
  justify-content: center;
}
.xRight {
  justify-content: right;
}
.yStart {
  align-items: flex-start;
}
.yCenter {
  align-items: center;
}
.yEnd {
  align-items: flex-end;
}

#logoContainer {
  z-index: 10;
  padding: 3px 7px;
  flex-direction: row;
}
.logo-preview {
  display: block;
  margin: 13px 3px; 
  max-height: 7vh;
  z-index: 100;
}
#itemTextContainer {
  padding-right: 12px;
  margin-right: 20px;
  padding: 25px;
}
p.ql-align-center {
  margin-top:1px;
  margin-bottom: .5em;
}
p.ql-align-right {
  margin-top:1px;
  margin-bottom: .5em;
}
</style>
