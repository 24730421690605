
import { modalController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const closeModal = () => {
      window.localStorage.setItem("tk2p_seenTutorial", "true");
      modalController.dismiss();
    };
    return {
      closeModal,
    };
  },
});
