<template>
  <ion-page>
    <ion-content class="t1 ion-no-padding" >
      <no-content v-if="!activeDeck && !activeProgram"></no-content>
      <single-card :itemData="subItemsSorted[activeIndex]" 
      :currentCoach="activeProgram" :fadeDirectionClass="fadeDirectionClass"
      @next="moveNext" @prev="movePrev" :psummary="positionSummary"></single-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  onIonViewDidEnter,
  modalController,
} from "@ionic/vue";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { isPlatform } from '@ionic/vue';
import NoContent from "@/components/NoContent.vue";
import Ins from "@/components/Modal/ScrollInstructions.vue";
import SingleCard from "@/components/SingleCard.vue"
// import { EffectFade, Navigation, Pagination, Virtual } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";


import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";
import "swiper/modules/pagination/pagination.min.css";

export default {
  name: "CuratedContent",
  components: {
    IonContent,
    IonPage,
    NoContent,
    SingleCard,
    // Swiper,
    // SwiperSlide,
  },

  setup() {
    onMounted(() => {
      console.log("ACTIVIATED");
    });
    const store = useStore();
    const isLoading = ref(false)
    
    const oneSignalId = ref(window.oneSignalId)
    const user = computed(() => store.getters.getCurrentUser);
    const curatedContent = computed(
      () => store.getters.getCurrentCuratedContent || {}
    );
    const currentCoach = computed(() => store.getters.getCoach);
    const axSetActiveDeck = store.dispatch("axSetActiveDeck");
    const axSetActiveProgram = store.dispatch("axSetActiveProgram", {
      programId: user.value.activeProgramId,
    });
    const activeProgram = computed(() => store.getters.getActiveProgram);
    const activeDeck = computed(() => store.state.subscription.activeDeck);
    const subItemsSorted = computed(() =>{
      const sI = [...activeDeck.value.subscriptionItems]
      return  sI.sort( (a,b) => {return a.programItem.order - b.programItem.order })
    })
    const activeIndex = computed(() => store.state.subscription.activeIndex);

   
    const axSetAppBranding = store.dispatch("axSetAppBranding", {
      coachId: activeProgram.value.coach.id,
    });
    const appBranding = computed(() => store.getters.getAppBranding);
    const deckItem = ref(0);
    const fadeDirectionClass = ref('animate__animated animate__fadeIn')
    const preserveHTML = computed(() => {
      if (
        !activeDeck.value.subscriptionItems[deckItem.value] ||
        !activeDeck.value.subscriptionItems[deckItem.value]
      )
        return "12345";
      return (
        activeDeck.value.subscriptionItems[deckItem.value].programItem.text
          .replaceAll("  ", "&nbsp;&nbsp;")
          .replaceAll("<p><br></p>", "<hr>") || ""
      );
    });
    const logoPositionClass = (item) => {
      return `${item.programItem.logoPositionX} ${item.programItem.logoPositionY}`;
    };
    const textPositionClass = computed(() => {
      return ` ${
        activeDeck.value.subscriptionItems[deckItem.value].programItem
          .textPositionY
      } `;
    });
    const getTextPreviewStyle = (programItem) => {
      return `font-size: ${programItem.fontSize}; font-family: ${programItem.fontFamily};color:${programItem.fontColor}`;
    };

    const getLogoPreviewStyle = (programItem) => {
      return `coachLogo  ${programItem.logoPositionY} ${programItem.logoPositionX}`;
    };
    const positionSummary = computed( () =>{
       const mx = subItemsSorted.value.length -1
      return  parseInt(activeIndex.value) >= parseInt(mx) ? "e" : activeIndex.value == 0 ? "s" : "m"
    })
    const moveNext = () =>{
      const mx = subItemsSorted.value.length - 1
      let newIndex = 0;
      newIndex = activeIndex.value >= mx ? mx : activeIndex.value + 1;
      store.dispatch('axUpdateActiveIndex', {index: newIndex})
      //Need Toggle to Make it work
      const fade0 = "animate__animated animate__fadeInRight "
      const fade1 = "animate__animated animate__fadeInRightBig "
      fadeDirectionClass.value = fadeDirectionClass.value == fade0 ? fade1 : fade0
  
    }
    const movePrev = () =>{
      let newIndex = 0;
      newIndex = activeIndex.value <= 0 ? 0 : activeIndex.value - 1;
      store.dispatch('axUpdateActiveIndex', {index: newIndex})
      const fade0 = "animate__animated animate__fadeInLeft "
      const fade1 = "animate__animated animate__fadeInLeftBig "
      fadeDirectionClass.value = fadeDirectionClass.value == fade0 ? fade1 : fade0
   
    }

    onIonViewDidEnter(async () => {
      isLoading.value = true;
      const seenTutorial =  true // -- because it doesn't work well... just hide for now window.localStorage.getItem("tk2p_seenTutorial") || false
      
      if(!seenTutorial){
      const m = await modalController.create({component:Ins})
      m.present();
      }
     

      if (!seenTutorial) {
        const m = await modalController.create({ component: Ins });
        m.present();
      }
       store.dispatch("axSetActiveDeck").then( ()=> isLoading.value = false);
    //Set OneSignal Id
    if(isPlatform('android') || isPlatform('ios')){
      window.plugins.OneSignal.setEmail(`${user.value.email}`);
      window.plugins.OneSignal.setExternalUserId(`tk2puser_${user.value.id}`)
      // const payload = {id: user.value.id, firstName: user.value.firstName , lastName: user.value.lastName, email: user.value.email, pushId: window.oneSignalId}
      // store.dispatch("axUpdateUser", payload).then ( (res)=>{
      //   console.log(res)
      // })
    }
    
    });
    const onSwiper = (swiper) => {
      console.log("wipe swiper", swiper);
     // store.dispatch("axActiveCard", activeDeck.value.subscriptionItems[0]);
    };
    const onSlideChange = (swiper) => {
      console.log("slide change", swiper.activeIndex);
    //   const value = swiper.activeIndex;
    //   const key = "deck_lastview_"
    //  store.dispatch("axActiveCard", activeDeck.value.subscriptionItems[card]);
    //  Storage.set({key, value})
    };
    

   // const modules =  [EffectFade, Navigation, Pagination, Virtual]
   

   

    return {
      oneSignalId,
      isLoading,
      getTextPreviewStyle,
      getLogoPreviewStyle,
      activeProgram,
      appBranding,
      preserveHTML,
      logoPositionClass,
      textPositionClass,
      user,
      currentCoach,
      curatedContent,
      axSetActiveDeck,
      subItemsSorted,
      axSetAppBranding,
      axSetActiveProgram,
      activeIndex,
      activeDeck,
      deckItem,
      onSwiper,
      onSlideChange,
      moveNext, movePrev, positionSummary, fadeDirectionClass
    //  modules,
    };
  },
};
</script>

<style lang="css" scoped>
.flex {
  display: flex;
  width: 100%;
  height: 100%;
}
#contentBody {
  text-align: center;
  padding: 40px;
  font-size: 64px;
  line-height: 54px;
  color: darkgreen;
}
#contentBackground {
  background-image: url("../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-preview {
  width: 100%;
  height: calc(100vh - 56px);
  background-size: cover;
  z-index: 10;
}
.text-preview {
  z-index: 500;
 
}


.bg-preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 10;
}
.overlayContainer {
  position: absolute;
  width: 100%;
  height: calc(100vh - 56px);
  padding: 7px;
  display: flex;
}
.overlayContainer > .text-preview {
  z-index: 50;
  width: 100%;
  
}
 p {
  margin-top: 0px !important;
}
.xLeft {
  justify-content: left;
}
.xCenter {
  justify-content: center;
}
.xRight {
  justify-content: right;
}
.yStart {
  align-items: flex-start;
}
.yCenter {
  align-items: center;
}
.yEnd {
  align-items: flex-end;
}

#logoContainer {
  z-index: -1;
  padding: 3px 7px;
  flex-direction: row;
}
.logo-preview {
  display: block;
  margin: 13px 3px;
 
  max-height: 7vh;

  z-index: 100;
}
#itemTextContainer {
  padding-right: 12px;
  margin-right: 20px;
  padding: 25px;
}
p.ql-align-center {
  margin-top:1px;
  margin-bottom: .5em;
}
p.ql-align-right {
  margin-top:1px;
  margin-bottom: .5em;
}
</style>
