<template>
  <ion-grid class="no-content" >
    <ion-row>
      <ion-col>
        <ion-card>
          <ion-card-header>
            <ion-card-title>
              <p class="text-center">No content yet !</p>
            </ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <p class="text-center">
              <img src="assets/images/decreased-concentration.png" alt="" />
            </p>
            <p class="text-center">
              Check back for curated content from this coach.
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
export default {
  name: "NoContent",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
};
</script>

<style>
p.text-center img {
  width: 80px;
}
.no-content {
  background-color: #f4f4f4;
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>